import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { useMediaQuery, Grid, Typography, Link } from "@material-ui/core";

import Logo from "@landingpage/app/src/images/logo.svg";
import Button from "../Button";
import Container from "../Container";

import StyledComponent from "./Footer.style";

const Footer = ({ year, footer, borderTop, logo, spacingTop }) => {
  if (!footer) return null;

  const classNames = [];
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  if (borderTop) {
    classNames.push("borderTop");
  }

  if (spacingTop) {
    classNames.push("spacingTop");
  }

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  return (
    <StyledComponent className={classNames.join(" ")}>
      <Container top bottom>
        <Grid container alignItems="center">
          <Grid className="logo left" item xs={12} sm={12} md={3}>
            <Logo width={logo.width} height={logo.height} />{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container alignItems="center">
              <Grid className="links" item xs={12} sm={12}>
                <nav>
                  <ul>
                    {footer.links.map((link, index) => (
                      <li key={index} className="center">
                        <Link
                          variant="body2"
                          color="textPrimary"
                          href={link.link.url}
                          target={link.link.target}
                        >
                          {RichText.asText(link.title.raw)}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
              </Grid>

              {isMobile && (
                <Grid className="button" item xs={12} sm={12}>
                  <Button
                    nostyling
                    className="center"
                    variant="outlined"
                    color="secondary"
                    href={footer.cta_link.url}
                    target={footer.cta_link.target}
                  >
                    {RichText.asText(footer.cta_title.raw)}
                  </Button>
                </Grid>
              )}

              <Grid className="copyright" item xs={12} sm={12}>
                <Typography className="center" variant="body2">
                  <small>
                    &copy;{year} {site.siteMetadata.title}
                  </small>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid
              className="button right mobile-center"
              item
              xs={12}
              sm={12}
              md={3}
            >
              <Button
                nostyling
                className="center"
                variant="outlined"
                href={footer.cta_link.url}
                target={footer.cta_link.target}
              >
                {RichText.asText(footer.cta_title.raw)}
              </Button>
            </Grid>
          )}
        </Grid>
      </Container>
    </StyledComponent>
  );
};

Footer.defaultProps = {
  footer: null,
  borderTop: false,
  logo: { heigth: 80, width: 85 },
};

Footer.propTypes = {};

export default Footer;
