import React, { useState, useEffect } from "react";
import { RichText } from "prismic-reactjs";
import {
  useScrollTrigger,
  AppBar,
  Toolbar,
  Typography,
  Slide,
  Grid,
  useMediaQuery,
} from "@material-ui/core";

import Logo from "@landingpage/app/src/images/logo.svg";
import Container from "../Container";
import Button from "../Button";

import StyledComponent from "./Topbar.style";
import NavigationBarTop from "./NavBarTop";
import NavigationBarBottom from "./NavBarBottom";
import { useRegistration } from "../Registration/RegistrationProvider";

const HideOnScroll = (props) => {
  const { children, window } = props;

  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const Topbar = ({ topbar, logo, logoSquare }) => {
  const { isAuth } = useRegistration();
  const [loading, setLoading] = useState(true);

  if (!topbar) return null;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const showMobile = isMobile && !loading;

  useEffect(() => setLoading(false), []);

  function hideNavBarBottom() {
    const appName = process.env.GATSBY_APP_NAME;

    switch (appName) {
      case "WithLove":
        return null;
      default:
        return (
          <NavigationBarBottom
            className="NavBarBottomHide"
            items={topbar.navigationbar}
          />
        );
    }
  }

  return (
    <StyledComponent>
      <HideOnScroll>
        <AppBar className="app-bar" position="fixed" color="inherit">
          <Toolbar disableGutters>
            <Container flexCenter={false}>
              <Grid
                className="app-bar-container"
                container
                direction="row"
                alignItems="center"
              >
                <Grid className="logo" item xs={false} sm={false}>
                  <Logo
                    width={logo.width}
                    height={logo.height}
                    className={logoSquare ? "logoSquare" : null}
                  />
                </Grid>
                <Grid className="info" item xs="auto" sm>
                  <Grid container direction="row" alignItems="center">
                    <Grid item md>
                      <Typography noWrap variant="h6">
                        {RichText.asText(topbar.description?.raw)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md="auto">
                      {!isAuth &&
                        topbar.call_to_actions.map((callToAction, index) => (
                          <Button
                            nostyling
                            key={index}
                            variant="outlined"
                            color="secondary"
                            href={callToAction.cta_link.url}
                            target={callToAction.cta_link.target}
                          >
                            {RichText.asText(callToAction.cta_title.raw)}
                          </Button>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      {!showMobile && (
        <NavigationBarTop items={topbar.navigationbar} className="NavBarTop" />
      )}
      {showMobile && hideNavBarBottom()}
    </StyledComponent>
  );
};

Topbar.defaultProps = {
  topbar: null,
  logo: { height: 80, width: 85 },
  logoSquare: false,
};

Topbar.propTypes = {};

export default Topbar;
