import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Background } from "react-imgix";
import { useMediaQuery } from "@material-ui/core";

const ResponsiveBackgroundImage = ({ children, image, className, cover }) => {
  if (!image.fluid?.src) return children;
  if (!image.thumbnails?.desktop) return children;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  let srcValue = image.thumbnails.desktop.fluid.src;
  if (isMobile) srcValue = image.thumbnails.mobile.fluid.src;

  return (
    <Background
      className={className}
      src={srcValue}
      imgixParams={{ fit: "max" }}
      htmlAttributes={{
        style: {
          backgroundSize: `${cover ? "cover" : "auto 100%"}`,
        },
      }}
    >
      {children}
    </Background>
  );
};

const StyledResponsiveBackgroundImage = styled(ResponsiveBackgroundImage)`
  background-size: ${({ cover }) => `${cover ? "cover" : "auto 100%"}`};
  background-color: transparent;
  background-repeat: no-repeat;
  width: 100%;
  background-position: ${({ position }) => `${position}`};
`;

StyledResponsiveBackgroundImage.defaultProps = {
  position: "center left",
  cover: false,
};

StyledResponsiveBackgroundImage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  image: PropTypes.shape({}).isRequired,
  position: PropTypes.string,
  cover: PropTypes.bool,
};

export default StyledResponsiveBackgroundImage;
