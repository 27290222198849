import styled from "styled-components";

const StyledButton = styled.div`
  position: relative;
  .MuiButton-root {
    ${({ theme }) => theme.breakpoints.down("sm")} {
      width: 100%;
    }

    padding: ${({ theme }) => `${theme.spacing(2)}px`}!important;

    ${({ theme }) => theme.breakpoints.up("md")} {
      min-width: 300px;
    }

    padding: ${({ theme }) => `${theme.spacing(2)}px`}!important;
  }

  .buttonProgress {
    color: ${({ theme }) => theme.palette.primary.main};
    position: absolute;
    top: 60%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
`;

export default StyledButton;
