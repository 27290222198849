import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// eslint-disable-next-line no-unused-vars
import lazySizes from "lazysizes";
import { useTheme } from "@material-ui/core/styles";
import Imgix, { Picture, Source } from "react-imgix";

const ResponsiveImage = ({ image, params, className }) => {
  const theme = useTheme();

  const attributes = {
    sizes: "100vw",
    imgixParams: {
      fit: "max",
    },
    attributeConfig: {
      src: "data-src",
      srcSet: "data-srcset",
      sizes: "data-sizes",
    },
    ...params,
  };

  if (!image.fluid?.src) return null;

  return (
    <div className={className}>
      <Picture>
        <Source
          src={image.thumbnails.mobile?.fluid.src}
          htmlAttributes={{
            media: theme.breakpoints.down("sm").replace("@media ", ""),
          }}
          {...attributes}
        />
        <Source
          src={image.thumbnails.tablet?.fluid.src}
          htmlAttributes={{
            media: theme.breakpoints.down("md").replace("@media ", ""),
          }}
          {...attributes}
        />
        <Source
          src={image.thumbnails.desktop?.fluid.src}
          htmlAttributes={{
            media: theme.breakpoints.down("lg").replace("@media ", ""),
          }}
          {...attributes}
        />

        <Imgix className="lazyload" src={image.fluid.src} {...attributes} />
      </Picture>
    </div>
  );
};

const StyledResponsiveImage = styled(ResponsiveImage)`
  width: 100%;

  > picture,
  > picture > img {
    width: 100%;
  }
`;

StyledResponsiveImage.defaultProps = {};

StyledResponsiveImage.propTypes = {
  image: PropTypes.shape({}).isRequired,
  params: PropTypes.shape({}),
};

export default StyledResponsiveImage;
