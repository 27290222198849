import React from "react";
import Link from "@material-ui/core/Link";
import { RichText } from "prismic-reactjs";
import {
  useScrollTrigger,
  AppBar,
  Toolbar,
  Typography,
  Slide,
} from "@material-ui/core";
import PropTypes from "prop-types";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Container from "../Container";
import StyledComponent from "./Topbar.style";

function HideOnScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function renderDivider() {
  const appName = process.env.GATSBY_APP_NAME;

  switch (appName) {
    case "WithLove":
      return <FavoriteIcon className="iconDivider" />;
    default:
      return <div className="iconDivider"> | </div>;
  }
}

const NavigationBarTop = ({ items }) => {
  if (!items || items.length === 0) return null;

  return (
    <StyledComponent>
      <HideOnScroll>
        <AppBar position="static" className="NavBarTop">
          <Container>
            {items.map(({ item_title, primary_color, item_anchor }, index) => (
              <Toolbar
                key={RichText.asText(item_title.raw)}
                className={primary_color ? "primaryText" : "normalText"}
              >
                <Typography>
                  <Link
                    color="textPrimary"
                    href={RichText.asText(item_anchor.raw)}
                  >
                    {RichText.asText(item_title.raw)}
                  </Link>
                </Typography>
                {index !== items.length-1 && renderDivider()}
              </Toolbar>
            ))}
          </Container>
        </AppBar>
      </HideOnScroll>
    </StyledComponent>
  );
};

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default NavigationBarTop;
