import React from "react";
import { ResponsiveBackgroundImage } from "../Image";

import StyledComponent from "./Container.style";

const Content = ({ children, image, paddingRight, paddingLeft }) => {
  const classNames = ["padding"];
  if (!paddingRight) classNames.push("noPaddingRight");
  if (!paddingLeft) classNames.push("noPaddingLeft");

  const content = (
    <div className={classNames.join(" ")}>
      <div className="content">{children}</div>
    </div>
  );

  if (image) {
    return (
      <ResponsiveBackgroundImage image={image} cover>
        <div className="content">{content}</div>
      </ResponsiveBackgroundImage>
    );
  }

  return content;
};

const Container = ({
  children,
  top,
  bottom,
  flexCenter,
  image,
  paddingRight,
  paddingLeft,
  className,
}) => {
  const classNames = [className];

  if (top) classNames.push("marginTop");
  if (bottom) classNames.push("marginBottom");
  if (flexCenter) classNames.push("flexCenter");



  return (
    <StyledComponent className={classNames.join(" ")}>
      <Content
        image={image}
        paddingRight={paddingRight}
        paddingLeft={paddingLeft}
      >
        {children}
      </Content>
    </StyledComponent>
  );
};

Container.defaultProps = {
  top: false,
  bottom: false,
  flexCenter: true,
  image: false,
  paddingRight: true,
  paddingLeft: true,
  className: ""
};

export default Container;
