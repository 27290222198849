import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Imgix from "react-imgix";

const Image = ({ image, params, className }) => {
  if(!image?.fluid?.src) return null;

  return (
    <div className={className}>
      <Imgix
        sizes="100vw"
        src={image.fluid.src}
        imgixParams={{ fit: "clip" }}
        {...params}
      />
    </div>
  );
};

const StyledImage = styled(Image)`
  max-width: 100%;

  img {
    max-width: 100%;
  }
`;

StyledImage.defaultProps = {};

StyledImage.propTypes = {
  image: PropTypes.shape({}).isRequired,
  params: PropTypes.shape({}),
};

export default StyledImage;
