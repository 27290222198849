import styled from "styled-components";

const StyledComponent = styled.div`
  .app-bar {
    background-color: ${({ theme }) => theme.palette.background.topbar};
  }
  .logo {
    position: relative;
    width: ${({ theme }) => `${85 + theme.spacing(2)}px`};

    > .logoSquare {
      position: absolute;
      top: ${({ theme }) => `-${theme.spacing(3)}px`};
      left: 0;
    }
  }
  .MuiButton-outlinedSecondary{
    color: ${({ theme }) => theme.palette.primary.main};
    border: 1px;
    border-color: ${({ theme }) => theme.palette.primary.main};
    border-style: solid;
  }

  .MuiToolbar-regular {
    min-height: 70px;
  }

  .MuiButton-root {
    padding: ${({ theme }) =>
      `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`};
    font-size: 1rem;
  }

  .iconDivider {
    padding-left: 50px;
    color: ${({ theme }) => theme.palette.text.navBar};
    svg:last-of-type {
      display: none;
    }
  }

  .NavBarTop {
    background-color: white;
    box-shadow: 0 6px 4px -4px rgba(0, 0, 0, 0.7);
    text-align: center;
    .content {
      display: inline-flex;
      place-content: center;
    }

    .MuiTypography-root {
      text-align: center;
      color: ${({ theme }) => theme.palette.text.navBar};
    }
    .MuiToolbar-root {
      min-height: 50px;
    }
    .MuiSvgIcon-root {
      height: 0.6em;
      font-size: 0.9em;
      fill: ${({ theme }) => theme.palette.text.navBar};
    }
  }

  .MuiBottomNavigation-root {
    z-index: 995;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #ddd;
    max-width: 100%;
  }

  .primaryButton {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: white;
    margin-top: -15px;
    -webkit-box-shadow: 0px 0px 10px 0px #000000;
    box-shadow: 0px 0px 10px 0px #000000;
    text-align-last: center;
    min-width: max-content;
  }

  .primaryText {
    display: none;
  }

  .navBarIcons {
    width: 20px;
    height: 20px;
    padding-top: 5px;
  }

  .MuiBottomNavigationAction-label {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    font-size: 0.6rem;
    font-weight: 500;
  }

  .MuiBottomNavigation-root {
    position: fixed;
    bottom: 0%;
    width: 100%;
    opacity: 100;
    background-color: ${({ theme }) => theme.palette.background.topbar};
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    .info {
      padding: ${({ theme }) => `${theme.spacing() / 2}px 0px`};
      text-align: right;

      > div {
        flex-direction: column;
        align-items: flex-end;
      }

      h6 {
        display: none;
      }

      .MuiButton-root {
        padding: ${({ theme }) =>
          `${theme.spacing(0.5)}px ${theme.spacing()}px`};
        font-size: 0.95rem;
      }
    }
  }
`;

export default StyledComponent;
