import styled from "styled-components";

const StyledComponent = styled.footer`
  background-color: ${({ theme }) => theme.palette.background.footer};

    &.borderTop {
      border-top: ${({ theme }) => `3px solid ${theme.palette.footer.border}`};
      margin-top: ${({ theme }) => `${theme.spacing(4)}px`};
    }

    &.spacingTop {
      margin-top: ${({ theme }) => `${theme.spacing(4)}px`};
    }

  .links > nav {
    > ul {
      margin: ${({ theme }) => `0px 0px ${theme.spacing(2)}px 0px`};
      padding: 0;
      list-style-type: none;

      > li {
        > a {
          font-size: 1rem;
          color: ${({ theme }) => theme.palette.footer.link};
        }
      }
    }
  }

  .copyright > p {
    > small {
      font-size: 0.8rem;
      color: #c7c7c7;
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: ${({ theme }) => `${theme.spacing(6)}px ${theme.spacing(2)}px`};

    .logo,
    .links,
    .button {
      text-align: center;
    }
    .links {
      margin-top: ${({ theme }) => `${theme.spacing(3)}px`};
    }
    .button {
      margin-top: ${({ theme }) => `${theme.spacing(2)}px`};
      text-align-last: center;
    }
    .copyright {
      margin-top: ${({ theme }) => `${theme.spacing(3)}px`};
      text-align-last: center;
    }
  }
`;

export default StyledComponent;
