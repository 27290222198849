import React from "react";
import { BottomNavigationAction } from "@material-ui/core";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import { RichText } from "prismic-reactjs";
import StyledComponent from "./Topbar.style";
import { ResponsiveImage } from "../Image";

const NavigationBarBottom = ({ items }) => {
  const openLink = (linkUrl) => {
    window.location = linkUrl;
  };

  if (!items || items.length === 0) return null;

  return (
    <StyledComponent>
      <div className="bottomNavBarItems">
        <BottomNavigation className="NavBarBottom" showLabels>
          {items.map(
            ({
              item_title,
              item_image,
              primary_color,
              item_anchor,
              item_link,
            }) => (
              <BottomNavigationAction
                onClick={() => openLink(RichText.asText(item_anchor.raw))}
                key={RichText.asText(item_title.raw)}
                label={RichText.asText(item_title.raw)}
                showlabel="true"
                icon={
                  <ResponsiveImage image={item_image} className="navBarIcons" />
                }
                className={primary_color ? "primaryButton" : "normalButton"}
                href={item_link.url}
              />
            )
          )}
        </BottomNavigation>
      </div>
    </StyledComponent>
  );
};

export default NavigationBarBottom;
