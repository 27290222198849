import styled from "styled-components";

const StyledComponent = styled.div`
  .padding {
    padding-left: ${({ theme }) => `${theme.spacing(8)}px`};
    padding-right: ${({ theme }) => `${theme.spacing(8)}px`};

    ${({ theme }) => theme.breakpoints.only("md")} {
      padding-left: ${({ theme }) => `${theme.spacing(4)}px`};
      padding-right: ${({ theme }) => `${theme.spacing(4)}px`};
    }

    ${({ theme }) => theme.breakpoints.only("sm")} {
      padding-left: ${({ theme }) => `${theme.spacing(2)}px`};
      padding-right: ${({ theme }) => `${theme.spacing(2)}px`};
    }

    ${({ theme }) => theme.breakpoints.only("xs")} {
      padding-left: ${({ theme }) => `${theme.spacing(2)}px`};
      padding-right: ${({ theme }) => `${theme.spacing(2)}px`};
    }

    ${({ theme }) => theme.breakpoints.between("md", "xl")} {
      &.noPaddingRight {
        padding-right: 0;
      }

      &.noPaddingLeft {
        padding-left: 0;
      }
    }
  }

  &.flex {
    display: flex;
    justify-content: center;
  }

  width: 100%;

  &.marginTop {
    padding-top: ${({ theme }) => `${theme.spacing(4)}px`};

    ${({ theme }) => theme.breakpoints.only("md")} {
      padding-top: ${({ theme }) => `${theme.spacing(4)}px`};
    }

    ${({ theme }) => theme.breakpoints.only("sm")} {
      padding-top: ${({ theme }) => `${theme.spacing(4)}px`};
    }

    ${({ theme }) => theme.breakpoints.only("xs")} {
      padding-top: ${({ theme }) => `${theme.spacing(2)}px`};
    }
  }

  &.marginBottom {
    padding-bottom: ${({ theme }) => `${theme.spacing(4)}px`};

    ${({ theme }) => theme.breakpoints.only("md")} {
      padding-bottom: ${({ theme }) => `${theme.spacing(4)}px`};
    }

    ${({ theme }) => theme.breakpoints.only("sm")} {
      padding-bottom: ${({ theme }) => `${theme.spacing(4)}px`};
    }

    ${({ theme }) => theme.breakpoints.only("xs")} {
      padding-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
    }
  }

  .content {
    max-width: ${({ theme }) => `${theme.breakpoints.values.xl}px`};
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
  }
`;

export default StyledComponent;
