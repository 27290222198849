import React from "react";
import PropTypes from "prop-types";
import { Button as MuiButton } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';

import StyledButton from "./Button.style";

const Button = (props) => {
  const { id, children, nostyling, loading, disabled } = props;

  const allProps = { ...props };
  delete allProps.nostyling;
  delete allProps.loading;

  if (nostyling) {
    return (
      <MuiButton data-test-id={id} {...allProps}>
        {children}
      </MuiButton>
    );
  }

  return (
    <StyledButton>
      <MuiButton data-test-id={id} disabled={loading || disabled} {...allProps}>
        {children}
      </MuiButton>
      {loading && <CircularProgress size={24} className="buttonProgress" />}
    </StyledButton>
  );
};

Button.defaultProps = {
  variant: "contained",
  color: "default",
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  id: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
};

export default Button;
